import Swal from './sweetalert2_11.js';

const changeProfilePictureBtn = document.getElementById('edit-profile-picture');

// delete profile picture Btn
const deleteProfilePictureBtn = document.getElementById(
  'delete-profile-picture',
);

// Update the profile picture on the page
function updateProfilePicture(newProfilePictureUrl) {
  const profilePictureBox = document.getElementById('profile-picture');
  const profilePicturePreview = document.getElementById(
    'profile-picture-preview',
  );
  const profilePictureAbbreviation = document.getElementById(
    'profile-picture-abbreviation',
  );

  const sidebarProfilePictureBox = document.getElementById(
    'sidebar-profile-picture',
  );
  const profilePictureSidebar = document.getElementById(
    'profile-picture-sidebar',
  );
  const profilePictureAbbreviationSidebar = document.getElementById(
    'profile-picture-abbreviation-sidebar',
  );

  if (newProfilePictureUrl) {
    // Update the profile picture on the account page
    if (profilePicturePreview) {
      profilePicturePreview.src = newProfilePictureUrl;
    } else {
      // Create new image element if not available
      const imgElement = document.createElement('img');
      imgElement.src = newProfilePictureUrl;
      imgElement.alt = 'Profile Picture';
      imgElement.className = 'w-full h-full object-cover';
      imgElement.id = 'profile-picture-preview';

      // Remove the abbreviation
      profilePictureAbbreviation.remove();

      const newProfilePictureDiv = document.createElement('div');
      newProfilePictureDiv.className =
        'h-20 w-20 flex items-center justify-center rounded-full overflow-hidden border-2 border-white';
      newProfilePictureDiv.appendChild(imgElement);

      profilePictureBox.appendChild(newProfilePictureDiv);
    }

    // Update the sidebar profile picture
    if (profilePictureSidebar) {
      profilePictureSidebar.src = newProfilePictureUrl;
    } else {
      // Create new image element if not available
      const imgElement = document.createElement('img');
      imgElement.src = newProfilePictureUrl;
      imgElement.alt = 'Profile Picture';
      imgElement.className = 'w-full h-full object-cover';
      imgElement.id = 'profile-picture-sidebar';

      // Remove the abbreviation
      profilePictureAbbreviationSidebar?.remove();

      const newProfilePictureDiv = document.createElement('div');
      newProfilePictureDiv.className =
        'h-20 w-20 flex items-center justify-center rounded-full overflow-hidden border-2 border-white';
      newProfilePictureDiv.appendChild(imgElement);
      sidebarProfilePictureBox?.appendChild(newProfilePictureDiv);
    }
  } else {
    Swal.fire({
      title: 'Error',
      text: 'Failed to update profile picture.',
      icon: 'error',
    });
  }
}

changeProfilePictureBtn?.addEventListener('click', () => {
  const profilePictureBox = document.getElementById('profile-picture-preview');
  const profilePictureAbbreviation = document.getElementById(
    'profile-picture-abbreviation',
  );
  const currentProfilePicture = profilePictureBox?.src;
  const alternativeProfilePicture = profilePictureAbbreviation?.textContent;
  Swal.fire({
    title: 'Change Profile Picture',
    html: `
      <div id="profile-picture-swal-preview" class="rounded-circle mx-auto mb-3 border border-2 border-[#242424] text-5xl font-semibold flex justify-center items-center" style="width: 150px; height: 150px; background-image: url('${
        currentProfilePicture || ''
      }'); background-size: cover; background-position: center;">${
        alternativeProfilePicture || ''
      }</div>
      <input type="file" id="profile-picture-input" accept="image/*" style="display: none;">
      <button id="select-picture-btn" class="btn btn-primary">Select Picture</button>
    `,
    showCancelButton: true,
    confirmButtonText: 'Upload',
    cancelButtonText: 'Cancel',
    showLoaderOnConfirm: true,
    preConfirm: () => {
      const file = document.getElementById('profile-picture-input').files[0];
      if (!file) {
        Swal.showValidationMessage('Please select an image file');
        return false;
      }

      const formData = new FormData();
      formData.append('profile_picture', file);
      formData.append(
        'csrfmiddlewaretoken',
        document.querySelector('[name="csrfmiddlewaretoken"]').value,
      );

      console.log('Form data:', formData.get('profile_picture'));

      return fetch('/api/accounts/update-profile-picture/', {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .catch((error) => {
          Swal.showValidationMessage(`Upload failed: ${error.message}`);
        });
    },
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: 'Success!',
        text: 'Your profile picture has been updated.',
        icon: 'success',
      });

      updateProfilePicture(result.value.avatar);
    }
  });

  document
    .getElementById('select-picture-btn')
    .addEventListener('click', () => {
      document.getElementById('profile-picture-input').click();
    });

  document
    .getElementById('profile-picture-input')
    .addEventListener('change', (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const previewElement = document.getElementById(
            'profile-picture-swal-preview',
          );
          previewElement.style.backgroundImage = `url('${e.target.result}')`;
          previewElement.textContent = '';
          previewElement.style.backgroundSize = 'cover';
          previewElement.style.backgroundPosition = 'center';
        };
        reader.readAsDataURL(file);
      }
    });
});

// Delete profile picture logic
deleteProfilePictureBtn?.addEventListener('click', () => {
  Swal.fire({
    title: 'Are you sure?',
    text: "This will delete your profile picture. You can't undo this action.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'Cancel',
    showLoaderOnConfirm: true,
    preConfirm: () => {
      return fetch('/api/accounts/delete-profile-picture/', {
        method: 'DELETE',
        headers: {
          'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]')
            .value,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .catch((error) => {
          Swal.showValidationMessage(`Delete failed: ${error.message}`);
        });
    },
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: 'Deleted!',
        text: 'Your profile picture has been deleted.',
        icon: 'success',
      });

      // Update the UI to show default profile picture or initials
      const abbreviation_pic = result.value.abbreviation;

      const spanBox = document.createElement('span');
      spanBox.textContent = abbreviation_pic;

      const sidebarProfilePictureBox = document.getElementById(
        'sidebar-profile-picture',
      );
      const profilePictureBox = document.getElementById('profile-picture');

      if (sidebarProfilePictureBox) {
        let sideSpanBox = spanBox.cloneNode(true);
        sideSpanBox.id = 'profile-picture-abbreviation-sidebar';
        sideSpanBox.className =
          'rounded-full text-5xl font-semibold bg-white h-20 w-20 flex justify-center items-center';
        sidebarProfilePictureBox.innerHTML = '';
        sidebarProfilePictureBox.appendChild(sideSpanBox);
      }

      if (profilePictureBox) {
        spanBox.className =
          'rounded-full border-2 border-[#242424] text-5xl font-semibold h-20 w-20 flex items-center justify-center';
        spanBox.id = 'profile-picture-abbreviation';
        profilePictureBox.innerHTML = '';
        profilePictureBox.appendChild(spanBox);
      }
    }
  });
});
